@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-primary: #0066ff;
  --color-low: #f2c94c;
  --color-medium: #f2994a;
  --color-high: #eb5757;
  --color-sidebar: #f2f2f2;
  // CHOOSE COLOR
  --color-1: #333333;
  --color-2: #804b9b;
  --color-3: #c21d7d;
  --color-4: #2b75b2;
  --color-5: #1a994e;
  --color-6: #333333;
  font-size: 16px;
}

.mapboxgl-ctrl-group button {
  width: 36px;
  height: 36px;
}

@media only screen and (max-width: 768px) {
  .mapboxgl-ctrl-top-right {
    top: 63px;
  }
}

html {
  @apply text-noah-black-high;
}

.checkbox {
  --border-color: black;
  --color: #3f83f8;
  // --color: black;

  display: flex;
  align-items: center;

  input[type='checkbox'] {
    appearance: none;
    color-adjust: exact;
    vertical-align: middle;
    background-origin: border-box;
    user-select: none;
    flex-shrink: 0;
    height: 20px;
    width: 20px;
    color: var(--color);
    background-color: #fff;
    border-color: var(--border-color);
    border-width: 1px;
    border-radius: 0.25rem;
    border-style: solid;

    &:checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
      border-color: transparent;
      background-color: currentColor;
      background-size: 100% 100%;
      background-position: 50%;
      background-repeat: no-repeat;
    }

    @media not print {
      &::-ms-check {
        border-width: 1px;
        color: transparent;
        background: inherit;
        border-color: inherit;
        border-radius: inherit;
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
      border-color: #a4cafe;
    }

    &:checked:focus {
      border-color: transparent;
    }
  }
}
